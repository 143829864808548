import React from "react"

import Layout from "../../layouts/Fi"

import SEO from "../../components/SEO"
import Card from "../../components/Card"
import CheckPoint from "../../components/Checkpoint"

import Export from "../../icons/export.svg"
import Graph from "../../icons/graph.svg"
import Search from "../../icons/search.svg"
import Shield from "../../icons/shield.svg"

const MPowerPage = () => (
  <Layout title="M-Power">
    <SEO keywords={[`m-power`]} title="M-Power" />

    <section className="flex justify-center">
      <Card className="w-full sm:w-3/4 md:w-2/3 mb-16">
        <div className="flex mb-4">
          <div className="w-10 mr-4">
            <Graph className="text-orange" />
          </div>
          <div className="w-auto">
            <h3 className="mb-4">
              Tiedon helppo esilletuonti näyttäviin graafeihin
            </h3>
            <p>
              M-Powerilla taulukoiden ja graafien esilletuonti, sekä tietojen
              muokkaus on helppoa ja vaivatonta.
            </p>
          </div>
        </div>

        <div className="flex mb-4">
          <div className="w-10 mr-4">
            <Search className="text-orange" />
          </div>
          <div className="w-auto">
            <h3 className="mb-4">Informaation nopea haku</h3>
            <p>
              Kertaluonteiseen tilanteeseen tarvittavan informaation haku, esim.
              edellisen vuoden puhelinkulut, on yksinkertaista.
            </p>
          </div>
        </div>

        <div className="flex mb-4">
          <div className="w-10 mr-4">
            <Export className="text-orange" />
          </div>
          <div className="w-auto">
            <h3 className="mb-4">Tietojen vienti</h3>
            <p>
              Aineistosta saa suoraan Office raportteihin (Word, Excel, Power
              Point) numeerisia ja graafisia tietoja.
            </p>
          </div>
        </div>

        <div className="flex">
          <div className="w-10 mr-4">
            <Shield className="text-orange" />
          </div>
          <div className="w-auto">
            <h3 className="mb-4">Tiedon turvallinen säilytys</h3>
            <p className="mb-0">
              M-Power -ohjelmiston vahva salaustaso takaa tietojesi turvallisen
              säilytyksen.
            </p>
          </div>
        </div>
      </Card>
    </section>

    <section className="flex justify-center">
      <div className="w-full sm:w-3/4 md:w-2/3">
        <p className="mb-16">
          M-Power on yrityssuunnitteluohjelmisto, navigaattori, jonka avulla
          saat yrityksellesi realistiset tuloslaskelmaennusteet koko
          tilikaudelle. Tämän realistisen reitin asettamiisi tavoitteisiin
          M-power suunnittelee laskentamoottorillaan yhdistämällä kirjanpidon
          historiatiedot käyttäjän tulevaisuusarvioihin.
        </p>
        <h3 className="mb-4">Autopilotti</h3>
        <p>
          M-power on työkalu, autopilotti, joka auttaa johtajaa tekemään
          tarvittaessa nopeita ratkaisuja, välittömiä suunnanmuutoksia. Näet
          reaaliajassa heikotkin signaalit ja erilaisten muutosvaihtoehtojen
          vaikutuksen reittiin ja päämäärään.
        </p>
        <p className="mb-16">
          M-Powerilla tapahtuu tiedonsiirto kirjanpidosta, soft-tiedon
          käsittely, heikkojen signaalien systemaattinen haku ja graafinen
          simulointi. Ohjelmassa on faktatilikartta, reaalisuusanalyysi,
          tunnuslukueditori, kuukausi-, osavuosi ja vuosituloslaskelman ennuste-
          ja analyysiraportti sekä MS-Officen kautta tapahtuva vapaamuotoinen
          raportointi.
        </p>
        <h3 className="mb-4">Business Intelligence</h3>
        <p className="mb-16">
          M-Power tuottaa yrityksen liiketoimintatietojen hallintaan, Business
          Intelligence, tarvittavat palvelut. Sen tietovirta, tietojen
          prosessointi ja raportointi on suunniteltu BI käyttöön. M-Power toimii
          suoraviivaisesti kattaen tärkeimmät Business Intelligence toiminnot.
          Sen avulla tiedon kokoaminen ja tunnuslukujen luominen on helppoa.
        </p>
      </div>
    </section>

    <section className="flex justify-center">
      <Card className="w-full sm:w-3/4 md:w-2/3">
        <CheckPoint className="mb-4">
          M-Power on helppokäyttöinen, monipuolinen ja vuorovaikutteinen.
        </CheckPoint>
        <CheckPoint className="mb-4">
          Budjetin toteutuman seuranta ja vertailu on mahdollista usealle
          tilikaudelle eteen- ja taaksepäin.
        </CheckPoint>
        <CheckPoint className="mb-4">
          Faktatietojen mukaanottaminen taloustietojen analysointiin on helppoa
          ja tehokasta.
        </CheckPoint>
        <CheckPoint>
          M-Power ei sido ennaltamäärättyihin kaavoihin vaan sopeutuu
          joustavasti kulloiseenkin tarpeeseen.
        </CheckPoint>
      </Card>
    </section>
  </Layout>
)

export default MPowerPage
